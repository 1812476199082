import React from "react"
import { Container, Card, Label, Menu, Segment, Message, Icon } from "semantic-ui-react"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MisconceptionsByPlPage = ({ pageContext, data }) => {
  // all known PLs ["Java", "JavaScript", ...]
  const pls = data.pls.edges.map(({node}) => node.frontmatter.id);
  const pl = pageContext.pl;
  const plSuffix = pl ? `${pl}/` : ``;
  const status = pageContext.status;
  const statusPrefix = status ? `/${status}` : ``;
  const edgesToPresent = data.allMdx.edges.filter(({node}) => 
    (pl===undefined||node.fields.pl===pl) && (
      (status==="all") ||
      (status==="public" && node.frontmatter.status==="public") ||
      (status==="draft" && node.frontmatter.status==="draft") ||
      (status===undefined && node.frontmatter.status==="public")
    )
  );

  
  return (
    <Layout>
      <SEO title="Misconceptions" />
      <Container style={{ marginTop: "100px", marginBottom: "100px" }}>
        {status ? 
          <Segment inverted color="orange">

            <Menu inverted pointing color="orange" secondary>
              <Menu.Item header>Publication Status: </Menu.Item>
              <Menu.Item 
                as={Link} 
                to={`/all/misconceptions/${plSuffix}`}
                active={status === "all"}
              >
                Any Status
              </Menu.Item>
              <Menu.Item
                as={Link} 
                to={`/public/misconceptions/${plSuffix}`}
                active={status === "public"}
              >
                Public
              </Menu.Item>
              <Menu.Item
                as={Link} 
                to={`/draft/misconceptions/${plSuffix}`}
                active={status === "draft"}
              >
                Draft
              </Menu.Item>
            </Menu>
          </Segment>
        : ""}

        <h1>Misconceptions</h1>
        <p>Here are a couple of misconceptions. Many more to come...</p>

        <Menu secondary>
          <Menu.Item 
            as={Link} 
            to={`${statusPrefix}/misconceptions/`}
            active={pl === undefined}
          >
            Any Language
          </Menu.Item>
          {pls.map((itemPl) =>
            <Menu.Item
              key={itemPl}
              as={Link} 
              to={`${statusPrefix}/misconceptions/${itemPl}/`}
              active={itemPl === pl}
            >
              {itemPl}
            </Menu.Item>
          )}
        </Menu>

        <Message color='blue' icon>
        <Icon name='warning'/>
          <Message.Content>
            <Message.Header>Misconception Count Does Not Imply Language Difficulty</Message.Header>
            <p>
              The is <b>no</b> connection between the number of misconceptions listed here
              and the difficulty of the corresponding programming language.
              The effort <b>we</b> spent on collecting misconceptions is very different for different languages.
              It would be inappropriate to reason about the quality of languages 
              based on the number of misconceptions in this collection.
            </p>
            </Message.Content>
        </Message>

        <div style={{padding: '10px'}}></div>

        <Card.Group className="three">
          {edgesToPresent.map(({ node }, index) => (
            <Card
              key={index}
              as={Link}
              to={node.fields.slug}
              className="inverted"
              style={{
                background: "#db2828",
                boxShadow: "0 1px 3px 0 #fff, 0 0 0 2px #fff",
              }}
            >
              {node.fields.image ? (
                <>
                  <Image
                    className="ui image"
                    fluid={node.fields.image.childImageSharp.fluid}
                    alt={node.fields.name}
                  />
                  <Label
                    floating
                    color="red"
                    style={{ right: "1.2em", top: "-0.8em" }}
                  >
                    {node.fields.pl}
                  </Label>
                  {node.frontmatter.status==="deprecated" ? 
                    <Label
                      floating
                      color="black"
                      style={{ right: "2.8em", top: "1.6em" }}
                    >
                      Deprecated
                    </Label>
                  : ""}
                  {node.frontmatter.status==="draft" ? 
                    <Label
                      floating
                      color="yellow"
                      style={{ right: "1.8em", top: "1.6em" }}
                    >
                      DRAFT
                    </Label>
                  : ""}
                </>
              ) : null}
              <Card.Content>
                <Card.Header>{node.fields.name}</Card.Header>
                {/*
                    <Card.Meta>
                      {node.frontmatter.pl}
                    </Card.Meta>
                    */}
                <Card.Description>
                  {node.frontmatter.shortDescription}
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Container>
    </Layout>
  );
}

export default MisconceptionsByPlPage

export const query = graphql`
  {
    allMdx(
      filter: {
        fileAbsolutePath: { glob: "**/src/data/misconceptions/**/index.md" }
      }
      sort: { fields: fields___name, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
            name
            pl
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
          frontmatter {
            status
            shortDescription
          }
        }
      }
    }
    pls: allMdx(
      filter: { frontmatter: { type: {eq: "pl"}}}
    ) {
      edges {
        node {
          frontmatter {
            id
          }
        }
      }
    }
  }
`
